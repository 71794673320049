import React from 'react';
import {
	BooleanInput,
	TextInput,
	ReferenceInput,
	AutocompleteInput
} from 'react-admin';

import List from '../common/List';
import Filter from '../common/Filter';
import UserRow from './UserRow';

const optionText = choice => choice.name ? choice.name : '';


const UserFilter = (props) => (
	<Filter {...props}>
		<TextInput label="Search" source="q" alwaysOn />
		<ReferenceInput label="Customer" source="customer_id" reference="customers" sort={{ field: 'name', order: 'ASC' }} allowEmpty alwaysOn>
			<AutocompleteInput optionText={optionText} />
		</ReferenceInput>
		<BooleanInput label="Active" source="active" alwaysOn />
		<BooleanInput label="Admin" source="is_admin" alwaysOn />
		<BooleanInput label="Staff" source="is_staff" alwaysOn />
	</Filter>
);


const filterDefaultValues = {
    active: true,
}


const UserList = props => (
	<List {...props} filters={<UserFilter />} filterDefaultValues={filterDefaultValues} >
		<UserRow />
	</List>
);

export default UserList;