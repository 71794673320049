import { API_URL, GOOGLE_MAP_API_KEY } from './settings';

const options = {
	headers: new Headers({
		'Authorization': `Bearer ${localStorage.getItem('token')}`
	})
};

export const search = async (terms) => {
	const response = await fetch(`${API_URL}/search/live?q=${terms}`, options);
	const { results } = await response.json();
	return results;
};

export const fetchAdminDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/stats`, options);
	return await response.json();
};

export const fetchLineDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/lines/stats`, options);
	return await response.json();
};

export const fetchUsageDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/usages/stats`, options);
	return await response.json();
};

export const fetchBusinessDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/business/stats`, options);
	return await response.json();
};

export const fetchStarlinkDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/starlink/stats`, options);
	return await response.json();
};

export const fetchStockDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/stock/stats`, options);
	return await response.json();
};

export const fetchStarlinkSubscriptionStats = async () => {
	const response = await fetch(`${API_URL}/starlink/subscriptions/stats`, options);
	return await response.json();
};

export const fetchAccountingDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/accounting/stats`, options);
	return await response.json();
};

export const fetchDeliveryDashboardStats = async () => {
	const response = await fetch(`${API_URL}/admin/dashboard/delivery/stats`, options);
	return await response.json();
};

export const fetchInvoicesReport = async ({group_by, start_date, end_date}) => {
	const params = new URLSearchParams();
	if (group_by) {
		params.append('group_by', group_by);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}
	const response = await fetch(`${API_URL}/invoices/report?${params.toString()}`, options);
	return await response.json();
};

export const fetchInvoicesSimOnlyReport = async () => {
	const response = await fetch(`${API_URL}/invoices/simonly/report`, options);
	return await response.json();
};

export const forgotPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/forget_password`, { method: 'POST', body: data });
	return await response.json();
};

export const resetPassword = async (data) => {
	const response = await fetch(`${API_URL}/account/reset_password`, { method: 'POST', body: data });
	return await response.json();
};

/* Devices */
export const getDeviceUsage = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/usage`, options);
	return await response.json();
}

export const getDeviceBandwidth = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/bandwidth?include_details=True`, options);
	return await response.json();
}

export const syncDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/sync`, options);
	return await response.json();
}

export const syncDevices = async () => {
	const response = await fetch(`${API_URL}/devices/incontrol/sync`, options);
	return await response.json();
}

export const prepareDevice = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/prepare`, { ...options, method: 'PUT', body: data });
	return await response.json();
}

export const updateDevicePorts = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/ports`, { ...options, method: 'PUT', body: data });
	return await response.json();
}

export const returnDevice = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/return`, { ...options, method: 'PUT', body: data });
	return await response.json();
}

export const disassembleDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/disassemble`, { ...options, method: 'PUT' });
	return await response.json();
}

export const bulkCreateDevice = async (data) => {
	const response = await fetch(`${API_URL}/devices/bulk`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const getOpsRecords = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/records`, options);
	return await response.json();
}

export const getDevicesStats = async () => {
	const response = await fetch(`${API_URL}/stats/devices`, options);
	return await response.json();
}

export const setProviderDefault = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/provider_default`, { ...options, method: 'POST', body: data });
	return await response.json();
}

export const getProviderDefault = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/provider_default`, options);
	return await response.json();
}

export const rebootDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/reboot`, { ...options, method: 'POST' });
	return await response.json();
}

export const restartModemDevice = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/restart_modem`, { ...options, method: 'POST', body: data });
	return await response.json();
}

export const speedTestDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/speedtest`, { ...options, method: 'POST' });
	return await response.json();
}

export const bulkSpeedTestDevices = async (data) => {
	const response = await fetch(`${API_URL}/speedtests`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const getDeviceIcDatas = async (deviceId) => {
	// TODO replace url
	const response = await fetch(`${API_URL}/devices/${deviceId}`, options);
	const data = await response.json();
	return data.ic_datas;
}

export const getIcGroups = async (serverName, organizationId) => {
	const response = await fetch(`${API_URL}/incontrol/servers/${serverName}/organizations/${organizationId}/groups`, options);
	return await response.json();
};

export const incontrolMoveDevice = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/incontrol_move`, { ...options, method: 'PUT', body: data });
	return await response.json();
};

export const moveDevice = async (deviceId, data) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/move`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const bulkMoveDevice = async (data) => {
	const response = await fetch(`${API_URL}/devices/move`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const fetchUsagesDevice = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/fetch_usages`, { ...options, method: 'POST' });
	return await response.json();
};

/* Simcards */
export const bulkCreateSimCard = async (data) => {
	const response = await fetch(`${API_URL}/simcards/bulk`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const getSimCardStats = async () => {
	const response = await fetch(`${API_URL}/stats/simcards`, options);
	return await response.json();
};

export const removeFromSimPool = async (poolId, simId) => {
	const response = await fetch(`${API_URL}/simpools/${poolId}/simcards/${simId}`, { ...options, method: 'DELETE' });
	return await response.json();
};

export const updateSimCard = async (simId, data) => {
	const response = await fetch(`${API_URL}/simcards/${simId}`, { ...options, method: 'PUT', body: data });
	return await response.json();
};

export const getStatusSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/status`, options);
	return await response.json();
};

export const enableSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/enable`, { ...options, method: 'POST' });
	return await response.json();
};

export const disableSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/disable`, { ...options, method: 'POST' });
	return await response.json();
};

export const detachSimCardFromDevice = async (simId, data) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/detach`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const attachSimCardToDevice = async (simId, data) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/attach`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const getLastLocationUpdatesSimCard = async (simId) => {
	const response = await fetch(`${API_URL}/simcards/${simId}/last_location_updates`, options);
	return await response.json();
};

/* Simpools */
export const addSimcardsToSimPool = async (poolId, data) => {
	const response = await fetch(`${API_URL}/simpools/${poolId}/simcards`, { ...options, method: 'POST', body: data });
	return await response.json();
};

/* Usage */
export const getUsage = async ({ sim_id, device_id, site_id, start_date, end_date }) => {
	const params = new URLSearchParams();
	params.append('sort', 'date');
	params.append('order', 'ASC');

	if (sim_id) {
		params.append('sim_id', sim_id);
	}
	if (device_id) {
		params.append('device_id', device_id);
	}
	if (site_id) {
		params.append('site_id', site_id);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}

	const response = await fetch(`${API_URL}/usages?${params.toString()}`, options);
	return await response.json();
};

export const getUsageStats = async ({ sim_id, device_id, site_id, start_date, end_date, freq }) => {
	const params = new URLSearchParams();
	params.append('freq', freq || 'daily');

	if (sim_id) {
		params.append('sim_id', sim_id);
	}
	if (device_id) {
		params.append('device_id', device_id);
	}
	if (site_id) {
		params.append('site_id', site_id);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}

	const response = await fetch(`${API_URL}/stats/usages?${params.toString()}`, options);
	return await response.json();
};

export const getStarlinkUsageStats = async ({ starlink_service_line_id, start_date, end_date, freq }) => {
	const params = new URLSearchParams();
	params.append('freq', freq || 'daily');

	if (starlink_service_line_id) {
		params.append('starlink_service_line_id', starlink_service_line_id);
	}
	if (start_date) {
		params.append('start_date', start_date);
	}
	if (end_date) {
		params.append('end_date', end_date);
	}

	const response = await fetch(`${API_URL}/starlink_usages/stats?${params.toString()}`, options);
	return await response.json();
};

/* Freshdesk */
export const refreshFreshdesk = async () => {
	const response = await fetch(`${API_URL}/customers/sync`, options);
	return await response.json();
};

/* Lines */
export const syncLines = async () => {
	const response = await fetch(`${API_URL}/lines/sync`, options);
	return await response.json();
};

export const detachLineFromDevice = async (lineId, data) => {
	const response = await fetch(`${API_URL}/lines/${lineId}/detach`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const attachLineToDevice = async (lineId, data) => {
	const response = await fetch(`${API_URL}/lines/${lineId}/attach`, { ...options, method: 'POST', body: data });
	return await response.json();
};

/* Account */
export const getAccount = async () => {
	const response = await fetch(`${API_URL}/account`, options);
	return await response.json();
};

export const updateAccount = async (data) => {
	const response = await fetch(`${API_URL}/account`, { ...options, method: 'PUT', body: data });
	return await response.json();
};

/* Device Config */
export const getConfigBackups = async (deviceId) => {
	const response = await fetch(`${API_URL}/devices/${deviceId}/config_backups`, options);
	return await response.json();
}

/* Servers */
export const syncServer = async (serverId) => {
	const response = await fetch(`${API_URL}/servers/${serverId}/sync`, options);
	return await response.json();
};

export const refreshOrganizationsServer = async (serverId) => {
	const response = await fetch(`${API_URL}/servers/${serverId}/organizations`, options);
	return await response.json();
};

/* IPs */
export const bulkCreateIP = async (data) => {
	const response = await fetch(`${API_URL}/ips/bulk`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const pingIP = async (ipId) => {
	const response = await fetch(`${API_URL}/ips/${ipId}/ping`, { ...options, method: 'POST' });
	return await response.json();
};

/* Product Items */
export const bulkCreateProductItem = async (data) => {
	const response = await fetch(`${API_URL}/product_items/bulk`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const returnProductItem = async (id) => {
	const response = await fetch(`${API_URL}/product_items/${id}/return`, { ...options, method: 'POST' });
	return await response.json();
};

export const moveProductItem = async (productItemId, data) => {
	const response = await fetch(`${API_URL}/product_items/${productItemId}/move`, { ...options, method: 'POST', body: data });
	return await response.json();
}

export const reserveProductItem = async (productItemId, data) => {
	const response = await fetch(`${API_URL}/product_items/${productItemId}/reserve`, { ...options, method: 'POST', body: data });
	return await response.json();
}

export const bulkMoveProductItem = async (data) => {
	const response = await fetch(`${API_URL}/product_items/move`, { ...options, method: 'POST', body: data });
	return await response.json();
};

/* Data pricings */
export const createDataPricing = async (data) => {
	const response = await fetch(`${API_URL}/data_pricings`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const updateDataPricing = async (id, data) => {
	const response = await fetch(`${API_URL}/data_pricings/${id}`, { ...options, method: 'PUT', body: data });
	return await response.json();
}

export const fixDataPricing = async () => {
	const response = await fetch(`${API_URL}/data_pricings/fix`, { ...options, method: 'POST' });
	return await response.json();
};

/* Invoices */
export const sendInvoice = async (id, data) => {
	const response = await fetch(`${API_URL}/invoices/${id}/send`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const reverseInvoice = async (id) => {
	const response = await fetch(`${API_URL}/invoices/${id}/reverse`, { ...options, method: 'POST' });
	return await response.json();
};

export const cloneInvoice = async (id) => {
	const response = await fetch(`${API_URL}/invoices/${id}/clone`, { ...options, method: 'POST' });
	return await response.json();
};

export const markPaidInvoice = async (id) => {
	const response = await fetch(`${API_URL}/invoices/${id}/mark_paid`, { ...options, method: 'POST' });
	return await response.json();
};

export const markUnPaidInvoice = async (id) => {
	const response = await fetch(`${API_URL}/invoices/${id}/mark_unpaid`, { ...options, method: 'POST' });
	return await response.json();
};

export const odooPushInvoice = async (id) => {
	const response = await fetch(`${API_URL}/invoices/${id}/odoo/push`, { ...options, method: 'POST' });
	return await response.json();
};

/* Starlink */
export const provisionStarlink = async (data) => {
	const response = await fetch(`${API_URL}/starlink/provision`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const fetchStarlinkAccounts = async () => {
	const response = await fetch(`${API_URL}/starlink/accounts`, options);
	return await response.json();
};

export const fetchStarlinkSubscriptions = async ({ account_number, available }) => {
	const params = new URLSearchParams();
	if (account_number) {
		params.append('account_number', account_number);
	}
	if (available) {
		params.append('available', available);
	}
	const response = await fetch(`${API_URL}/starlink/subscriptions?${params.toString()}`, options);
	return await response.json();
};

export const fetchStarlinkTerminals = async ({ account_number, available }) => {
	const params = new URLSearchParams();
	if (account_number) {
		params.append('account_number', account_number);
	}
	if (available) {
		params.append('available', available);
	}
	const response = await fetch(`${API_URL}/starlink/terminals?${params.toString()}`, options);
	return await response.json();
};

export const deactivateStarlinkServiceLine = async (id) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${id}/deactivate`, { ...options, method: 'POST' });
	return await response.json();
};

export const optInStarlinkServiceLine = async (id) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${id}/optin`, { ...options, method: 'POST' });
	return await response.json();
};

export const optOutStarlinkServiceLine = async (id) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${id}/optout`, { ...options, method: 'POST' });
	return await response.json();
};

export const syncStarlinkServiceLine = async (id) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${id}/sync`, { ...options, method: 'POST' });
	return await response.json();
};

export const pauseStarlinkServiceLine = async (id) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${id}/pause`, { ...options, method: 'POST' });
	return await response.json();
};

export const resumeStarlinkServiceLine = async (id) => {
	const response = await fetch(`${API_URL}/starlink_service_lines/${id}/resume`, { ...options, method: 'POST' });
	return await response.json();
};

export const fetchStarlinkProducts = async ({ account_number }) => {
	const params = new URLSearchParams();
	if (account_number) {
		params.append('account_number', account_number);
	}
	const response = await fetch(`${API_URL}/starlink/products?${params.toString()}`, options);
	return await response.json();
};

/* Starlink terminals */
export const stowStarlinkTerminal = async (terminalId) => {
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/stow`, { ...options, method: 'POST' });
	return await response.json();
};

export const unstowStarlinkTerminal = async (terminalId) => {
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/unstow`, { ...options, method: 'POST' });
	return await response.json();
};

export const rebootStarlinkTerminal = async (terminalId) => {
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/reboot`, { ...options, method: 'POST' });
	return await response.json();
};

export const fetchStarlinkTerminalStats = async (terminalId, data) => {
	const params = new URLSearchParams();
	if (data?.name) {
		params.append('name', data.name);
	}
	if (data?.delta) {
		params.append('delta', data.delta);
	}
	if (data?.source) {
		params.append('source', data.source);
	}
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/stats?${params.toString()}`, options);
	return await response.json();
};

export const fetchStarlinkTerminalStatus = async (terminalId, data) => {
	const params = new URLSearchParams();
	if (data?.source) {
		params.append('source', data.source);
	}
	const response = await fetch(`${API_URL}/starlink_terminals/${terminalId}/status?${params.toString()}`, options);
	return await response.json();
};

/* Packages */
export const simulatePackage = async (packageId) => {
	const response = await fetch(`${API_URL}/packages/${packageId}/simulate`, options);
	return await response.json();
};

export const confirmPackage = async (packageId) => {
	const response = await fetch(`${API_URL}/packages/${packageId}/confirm`, { ...options, method: 'POST' });
	return await response.json();
};

export const startPackage = async (packageId, data) => {
	const response = await fetch(`${API_URL}/packages/${packageId}/start`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const stopPackage = async (packageId) => {
	const response = await fetch(`${API_URL}/packages/${packageId}/stop`, { ...options, method: 'POST' });
	return await response.json();
};

export const invoicePackage = async (packageId) => {
	const response = await fetch(`${API_URL}/packages/${packageId}/invoice`, { ...options, method: 'POST' });
	return await response.json();
};

export const clonePackage = async (packageId) => {
	const response = await fetch(`${API_URL}/packages/${packageId}/clone`, { ...options, method: 'POST' });
	return await response.json();
}; 

/* PackageLine */
export const provisionPackageLine = async (packageLineId, data) => {
	const response = await fetch(`${API_URL}/package_lines/${packageLineId}/provision`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const shipPackageLine = async (packageLineId, data) => {
	const response = await fetch(`${API_URL}/package_lines/${packageLineId}/ship`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const deliverPackageLine = async (packageLineId, data) => {
	const response = await fetch(`${API_URL}/package_lines/${packageLineId}/deliver`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const bulkShipPackageLines = async (data) => {
	const response = await fetch(`${API_URL}/package_lines/ship`, { ...options, method: 'POST', body: data });
	return await response.json();
};

/* Services */
export const provisionService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/provision`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const shipService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/ship`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const deliverService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/deliver`, { ...options, method: 'POST', body: data });
	return await response.json();
};


export const pauseService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/pause`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const idleService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/idle`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const unidleService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/unidle`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const resumeService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/resume`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const assignService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/assign`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const bulkShipServices = async (data) => {
	const response = await fetch(`${API_URL}/services/ship`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const endService = async (serviceId, data) => {
	const response = await fetch(`${API_URL}/services/${serviceId}/end`, { ...options, method: 'POST', body: data });
	return await response.json();
};

/* Autocomplete */
export const getAutocomplete = async (resource, source) => {
	const response = await fetch(`${API_URL}/${resource}/values/${source}`, options);
	return await response.json();
};


/* MFA */
export const dispatchMFACode = async (data) => {
	const response = await fetch(`${API_URL}/account/login/mfa/dispatch_code`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const confirmMFACode = async (data) => {
	const response = await fetch(`${API_URL}/account/login/mfa/confirm_code`, { ...options, method: 'POST', body: data });
	return await response.json();
};

export const enableMFA = async (userId) => {
	const response = await fetch(`${API_URL}/users/${userId}/mfa/enable`, { ...options, method: 'POST' });
	return await response.json();
};

export const disableMFA = async (userId) => {
	const response = await fetch(`${API_URL}/users/${userId}/mfa/disable`, { ...options, method: 'POST' });
	return await response.json();
};

/* Impersonate */
export const impersonateUser = async (userId) => {
	const response = await fetch(`${API_URL}/users/${userId}/impersonate`, { ...options, method: 'POST' });
	return await response.json();
}

export const impersonateCustomer = async (customerId) => {
	const response = await fetch(`${API_URL}/customers/${customerId}/impersonate`, { ...options, method: 'POST' });
	return await response.json();
}

/* Google Maps */
export const getLatLngFromAddress = async (address) => {
	const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_MAP_API_KEY}`);
	const { results } = await response.json();
	return results?.length > 0 ? results[0]?.geometry?.location : null;
};
