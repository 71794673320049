import React from 'react';
import {
	TabbedShowLayout,
	Tab,
	TextField,
	ReferenceField,
	ReferenceManyField,
	Pagination,
	TabbedShowLayoutTabs
} from 'react-admin';
import { Link } from 'react-router-dom';
import IconAdd from '@material-ui/icons/Add';
import { Helmet } from 'react-helmet';
import { withStyles } from '@material-ui/core/styles';

import Show, { ShowActions } from '../common/Show';
import IconButton from '../common/IconButton';
import DateField from '../common/fields/DateField';
import BooleanField from '../common/fields/BooleanField';
import DeviceRow from '../devices/DeviceRow';
import UserRow from '../users/UserRow';
import ContactRow from '../contacts/ContactRow';
import PackageRow from '../packages/PackageRow';
import DataPricingRow from '../data_pricings/DataPricingRow';
import SiteRow from '../sites/SiteRow';
import SimPoolRow from '../simpools/SimPoolRow';
import LineRow from '../lines/LineRow';
import AddressRow from '../addresses/AddressRow';
import { FreshdeskLink } from './CustomerReferenceField';
import ImpersonateButton from './CustomerActionsButtons/ImpersonateButton';
import CustomList from '../common/CustomList';
import FilteredLogList from '../logs/FilteredLogList';
import DataPoolRow from '../data_pools/DataPoolRow';
import ProductItemRow from '../product_items/ProductItemRow';
import InvoiceRow from '../invoices/InvoiceRow';
import { ImpersonateButton as UserImpersonateButton } from '../users/UserActionsButtons/ImpersonateButton';

// TODO: fix width when menu closed
const styles = {
	show: {
		maxWidth: 'calc(100vw - 240px - 19px - 45px)'
	}
};


const CustomerTitle = ({ record }) => (
	<>
		<Helmet>
			<title>KB - Customer[{record.name}]</title>
		</Helmet>
		<span>{record ? `${record.name} ` : ''}</span>
	</>
);

const DeviceIcon = ({ record }) => (
	<IconButton component={Link} to={`/sites/${record.id}/build`} icon={<IconAdd />}>Add device</IconButton>
);

const SiteList = ({ resource, ...props }) => (
	<CustomList
		{...props}
		resource="sites"
		filter={{ customer_id: props.record.id }}
		sort={{ field: 'name', order: 'ASC' }}
		basePath="/sites"
	>
		<SiteRow exclude={['customer_id']}>
			<DeviceIcon />
		</SiteRow>
	</CustomList>
);

const CustomerActions = props => (
	<ShowActions {...props}>
		<ImpersonateButton record={props.data} />
		<FreshdeskLink record={props.data}>Freshdesk</FreshdeskLink>
	</ShowActions>
);

const CustomerShow = ({ classes, ...props }) => (
	<Show title={<CustomerTitle />} actions={<CustomerActions {...props} />} {...props} className={classes.show}>
		<TabbedShowLayout  tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="on" />}>
			<Tab label="sites">
				<SiteList />
			</Tab>
			<Tab label="summary" path="summary">
				<TextField label="Id" source="id" />
				<TextField source="name" />
				<TextField source="short_name" />
				<ReferenceField label="Account Manager" source="account_manager_id" reference="users" allowEmpty link={false}>
                    <TextField source="email" />
                </ReferenceField>
				<ReferenceField label="PreSales" source="presale_id" reference="users" allowEmpty link={false}>
                    <TextField source="email" />
                </ReferenceField>
				<TextField source="description" label="Notes" />
				<BooleanField source="nightswatch" />
				<TextField source="support_level" />
				<TextField source="support_email" />
				<TextField source="default_data_limit_contacts" />
				<DateField label="Created at" source="created_at" />
				<DateField label="Updated at" source="updated_at" />
				<DateField label="Archived at" source="archived_at" />
			</Tab>
			<Tab label="users" path="users">
				<ReferenceManyField addLabel={false} reference="users" target="customer_id" pagination={<Pagination />}>
					<UserRow exclude={['customer']}>
						<UserImpersonateButton />
					</UserRow>
				</ReferenceManyField>
			</Tab>
			<Tab label="devices" path="devices">
				<ReferenceManyField addLabel={false} reference="devices" target="customer_id" pagination={<Pagination />}>
					<DeviceRow />
				</ReferenceManyField>
			</Tab>
			<Tab label="SimPools" path="simpools">
				<ReferenceManyField  addLabel={false} reference="simpools" target="customer_id" pagination={<Pagination />}>
					<SimPoolRow exclude={['customer']}/>
				</ReferenceManyField >
			</Tab>
			<Tab label="lines" path="lines">
				<ReferenceManyField  addLabel={false} reference="lines" target="customer_id" pagination={<Pagination />}>
					<LineRow exclude={['customer']} />
				</ReferenceManyField >
			</Tab>
			<Tab label="contacts" path="contacts">
				<ReferenceManyField  addLabel={false} reference="contacts" target="customer_id" pagination={<Pagination />}>
					<ContactRow exclude={['customer']} />
				</ReferenceManyField >
			</Tab>
			<Tab label="Logs">
				<FilteredLogList />
			</Tab>
			<Tab label="Data Pricing" path="data_pricings">
				<ReferenceManyField addLabel={false} reference="data_pricings" target="customer_id" pagination={<Pagination />}>
					<DataPricingRow exclude={['customer']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="Packages" path="contracts">
				<ReferenceManyField addLabel={false} reference="packages" target="customer_id" pagination={<Pagination />}>
					<PackageRow exclude={['customer']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="Billing Addresses" path="addresses">
				<ReferenceManyField addLabel={false} reference="addresses" target="customer_id" pagination={<Pagination />}>
					<AddressRow exclude={['customer']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="DataPools" path="data_pools">
				<ReferenceManyField addLabel={false} reference="data_pools" target="customer_id" pagination={<Pagination />}>
					<DataPoolRow exclude={['customer']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="Product Items" path="product_items">
				<ReferenceManyField addLabel={false} reference="product_items" target="customer_id" pagination={<Pagination />} filter={{ archived: false }}>
					<ProductItemRow exclude={['customer', 'reserved']} />
				</ReferenceManyField>
			</Tab>
			<Tab label="Invoices" path="invoices">
				<ReferenceManyField addLabel={false} reference="invoices" target="customer_id" pagination={<Pagination />} filter={{ archived: false }}>
					<InvoiceRow exclude={['customer']} />
				</ReferenceManyField>
			</Tab>
		</TabbedShowLayout>
	</Show>
);

export default withStyles(styles)(CustomerShow);
