import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify } from 'react-admin';
import SendIcon from '@material-ui/icons/Send';

import IconButton from '../../common/IconButton';

import { odooPushInvoice } from '../../../services/api';


const OdooPushInvoiceButton = ({ record, variant, color }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);

    const handleConfirm = async () => {
        const response = await odooPushInvoice(record.id);
        if (response.id) {
            refresh();
            notify('Invoice pushed to odoo');
        }

        handleClose();
    };

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    if (!record || record.sent_at == null) {
        return null;
    }

    return (
        <>
            <IconButton icon={<SendIcon />} onClick={handleOpen} variant={variant} color={color}>Push to Odoo</IconButton>
            <Confirm
                isOpen={showDialog}
                title={`Push to Odoo #${record.id}`}
                content={`Are you sure you want to push to odoo this document ?`}
                onConfirm={handleConfirm}
                onClose={handleClose}
            />
        </>
    );
};

export default OdooPushInvoiceButton;
