import React from 'react';
import {
	TextField,
	ReferenceField,
} from 'react-admin';

import DateField from '../common/fields/DateField';
import Datagrid from '../common/Datagrid';
import BooleanField from '../common/fields/BooleanField';
import RecordSplitButton from '../common/RecordSplitButton';
import EnableMFAButton from './UserActionsButtons/EnableMFAButton';
import DisableMFAButton from './UserActionsButtons/DisableMFAButton';


const UserRow = ({ children, ...props }) => (
    <Datagrid {...props}>
        <TextField source="email" />
        <TextField source="firstname" />
        <TextField source="lastname" />
        <ReferenceField label="Customer" source="customer" reference="customers" link="show" allowEmpty>
            <TextField source="name" />
        </ReferenceField>
        <BooleanField label="is_admin" source="is_admin"/>
        <BooleanField label="is_staff" source="is_staff"/>
        <BooleanField label="is_manager" source="is_manager"/>
        <BooleanField label="active" source="active" />
        <BooleanField label="mfa_enabled" source="mfa_enabled" />
        <DateField label="Access at" source="last_login_at" showTime />
        {children}
        <RecordSplitButton hasEdit={false} archiveButton={<></>}>
            <EnableMFAButton />
            <DisableMFAButton />
        </RecordSplitButton>
    </Datagrid>
);

UserRow.defaultProps = {
	basePath: '/users'
};

export default UserRow;